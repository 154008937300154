.blog-list-entry {
  min-height: 40px;

  .link {
    text-decoration: none;
    color: white;
  }

  .blog-list-entry-container {
    display: flex;
    flex-direction: row;
  }

  .blog-list-entry-image {
    width: 100px;
    height: 100px;
    min-width: 100px;
    min-height: 100px;

    margin-right: 8px;
    border-radius: 10px;

    // Preserve aspect ratio regardless of image dimensions.
    object-fit: cover;
  }

  @media only screen and (max-width: 800px)
  {
    .blog-list-entry-image {
      max-width: 100px;
      max-height: 100px;
    }
  }

  .blog-list-entry-title {
    font-weight: bold;
    font-size: 30px;
  }

  .blog-list-entry-description {
    font-size: 16px;
    color: #D8D8D8;
  }

  .blog-list-entry-date {
    font-size: 14px;
    color: #848484;
  }
}
