.page-header-image {
  &.icon {
    background-color: #1E1E1E;
    border-radius: 10px;
    width: 180px;
    height: 180px;
    min-width: 100px;
    min-height: 100px;
    margin: 5px;

    // Preserve aspect ratio regardless of image dimensions.
    object-fit: cover;
  }

  @media only screen and (max-width: 800px)
  {
    &.icon {
      max-width: 100px;
      max-height: 100px;
    }
  }
}
