.work-header {
  & > .container {
    display: flex;
    flex-direction: row;

    & > .right-column {
      flex-direction: column;

      & > .header {
        display: block;
        font-size: 30px;
        font-weight: bold;
        margin: 3px auto;
      }

      & > .right-column-body {
        margin-left: 10px;
        margin-right: 5px;

        & > .position-and-location-container {
          & > .position {
            font-size: 16px;
            overflow: hidden;
            font-weight: bold;
          }
        }

        .work-header-line-height {
          line-height: 2;
        }
      }
    }
  }

  & > .footer-container {
    margin: 5px;
  }
}
