@import './common-style';

html {
  height: 100%;

  body {
    height: 100%;

    #root {
      height: 100%;
    }
  }
}

.App {
  display: flex;
  flex-direction: column;
  height: 100%;
  
  // Black background to prevent a white background flash during load.
  background-color: black;

  & > .body {
    position: relative;
    top: 0px;

    width: 100%;
    overflow: auto;

    // Needed to fill the page when the content isn't full.
    flex-grow: 1;

    background-image: url('./images/bg.jpg');
  }

  // Indents the first line of text like you would expect from a paragraph.
  .paragraph-with-indent {
    text-indent: 2.5em;
  }
}
