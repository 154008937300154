.two-xl-games-work {
  .project-title {
    font-size: 18pt;
    font-style: normal;
  }

  .project-description {
    margin-top: 10px;

    .game-description {
      text-align: left;
      font-style: normal;
      padding-top: 10px;
    }
  }

  .link {
    display: block;
    text-decoration: none;
    color: white;
    font-style: normal;
    margin-top: 10px;
  }
}
