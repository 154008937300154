.app-link {
  display: inline-block;
  vertical-align: top;
  text-decoration: none;
  margin-right: 10px;

  .app-image {
    height: 50px;
  }

  .site-box {
    position: relative;
    display: inline-block;
    border: 1px solid #999999;
    border-radius: 6px;
    width: 135px;
  }

  .site-box-text {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    font-size: 18pt;
    color: white;
    text-align: center;
  }
}
