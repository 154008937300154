.resume-page {
  padding: 48px 64px 84px;

  @media only screen and (max-width: 900px) {
    padding: 24px 32px 42px;
  }

  background-color: white;

  color: black;
  font-family: 'Calibri';

  .title-container {
    font-weight: bold;
    font-size: 24pt;
    text-align: center;

    color: #666666;

    // @media only screen and (max-width: 900px) {
    //   font-size: 18pt;
    // }

    // @media only screen and (max-width: 500px) {
    //   font-size: 12pt;
    // }

    font-size: 4vw;
  }

  .contact-details-container {
    font-size: 12pt;
    text-align: center;

    // @media only screen and (max-width: 900px) {
    //   font-size: 10pt;
    // }

    // @media only screen and (max-width: 500px) {
    //   font-size: 8pt;
    // }

    font-size: 2vw;
  }

  .self-description-snippet {
    font-size: 16pt;

    @media only screen and (max-width: 900px) {
      font-size: 12pt;
    }
  }

  .section {
    .section-header-label {
      font-size: 22pt;
      font-weight: bold;
      color: #666666;

      @media only screen and (max-width: 900px) {
        font-size: 16pt;
      }
    }

    .section-content {
      font-size: 16pt;

      @media only screen and (max-width: 900px) {
        font-size: 12pt;
      }

      ul {
        margin: 0;
        padding-left: 14px;
      }
    }

    .bold-label {
      font-weight: bold;

      @media only screen and (max-width: 900px) {
        font-size: 14pt;
      }
    }

    .list-box {
      &.col-count-dynamic {
        column-count: 3;
    
        @media only screen and (max-width: 900px) {
          column-count: 2;
        }
    
        @media only screen and (max-width: 500px) {
          column-count: 1;
        }
      }
    }
  }
}