.homepage-tiles-container {
  padding: 0 20px;

  .homepage-divider-bar {
    border-bottom: 2px solid white;
    height: 40px;
  }

  .homepage-divider-label {
    font-size: 24pt;
    color: white;
    margin-bottom: 0px;
    margin-left: 20px;
    margin: 0px;
  }

  .homepage-tiles {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 10px;
    padding: 20px 8px 10px 8px;
  }
}
