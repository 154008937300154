.work-list-header {
  font-size: 30px;
}

.work-list-list {
  margin: 0;

  &.col-count-dynamic {
    column-count: 3;

    @media only screen and (max-width: 900px) {
      column-count: 2;
    }

    @media only screen and (max-width: 500px) {
      column-count: 1;
    }
  }

  &.col-count-2 {
    column-count: 2;
  }

  &.col-count-3 {
    column-count: 3;
  }

  &.col-count-4 {
    column-count: 4;
  }
}
