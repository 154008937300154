.rss {
  position: relative;
  max-height: 100%;
  padding: 8px 0px;
  padding: 0px;

  display: flex;
  flex-direction: column;

  .description-box {
    // Relative position to help with placing the Close button.
    position: relative;

    color: white;
    font-size: 16pt;
    
    border: 2px solid white;
    border-radius: 10px;
    padding: 12px;
    margin: 0px 16px;

    .close-top-message {
      position: absolute;
      top: 4px;
      right: 4px;
    }
  }

  .request-info-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    color: white;
    padding: 14px;

    .pending-requests-container {
      @keyframes fadeOut {
        from {
          color: rgba(255, 255, 255, 1);
        }
      
        to {
          color: rgba(255, 255, 255, 0);
        }
      }

      &.no-pending-requests {
        animation-duration: 3s;
        animation-name: fadeOut;
        animation-fill-mode: forwards;
      }
    }

    .unhide-buttons-container {
      flex-shrink: 0;
      margin-left: 4px;
      text-align: right;
  
      .is-hidden {
        visibility: hidden;
      }
  
      .unhide-button {
        display: inline-block;
        color: white;
        padding: 2px;
        margin-left: 8px;
        margin-bottom: 2px;
        font-size: 10pt;
      }
    }
  }

  .rss-list {
    display: flex;
    flex-direction: column;
    overflow: auto;

    padding: 0px 16px;

    .saved-section-label {
      color: white;
      font-size: 22px;
      padding-bottom: 12px;
    }

    .saved-items-container {
      border: 2px solid red;
      padding: 10px 10px 0;
      margin-top: 40px;
      margin-bottom: 20px;
    }
  }

  .rss-button {
    border-left: 2px solid white;
    border-bottom: 2px solid white;
    border-radius: 8px;

    &:hover {
      border: initial;
      border-right: 1px solid white;
      border-top: 1px solid white;
    }
  }
}

@import './rss-close-button/index.scss';
@import './rss-list-item/index.scss';