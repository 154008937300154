.web-link,
.local-link {
  display: inline-block;

  & > .link-tag {
    text-decoration: none;
    color: white;
    font-style: normal;
    margin-top: 10px;
  }

  .link-icon {
    padding-left: 2px;
  }

  &:hover {
    text-decoration: underline;

    svg {
      // Apply an underline but try to undo the vertical push upward that the border causes.
      border-bottom: 1.8px solid white;
      margin-bottom: -3.2px;
      padding-bottom: 1.8px;
    }
  }
}
