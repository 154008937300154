body {
  margin: 0;
  font-family: helvetica;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@import './App.scss';
@import './blog/index.scss';
@import './footer/index.scss';
@import './header/index.scss';
@import './homepage-tile/index.scss';
@import './homepage-tiles/index.scss';
@import './pieces/index.scss';
@import './resume/index.scss';
@import './works/index.scss';