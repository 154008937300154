.nav-bar {
  font-family: verdana;
  font-weight: bold;
  font-size: 22pt;
  color: white;
  background-color: #1E1E1E;

  padding-left: 80px;
  padding-right: 80px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  height: 60px;
  min-height: 60px;

  @media only screen and (max-width: 600px)
  {
    font-size: 12pt;
    padding-left: 8px;
    padding-right: 8px;
  }

  .jg-logo-link {
    margin-right: 40px;

    @media only screen and (max-width: 600px) {
      & {
        margin-right: 10px;
      }
    }
  }

  .jg-logo {
    height: 40px;
    vertical-align: middle;
    border-radius: 4px;
  }

  .nav-buttons-container {
    .nav-button-container {
      display: inline-block;
      text-align: center;
      line-height: 40px;
    }
  }

  .nav-bar-link {
    margin-right: 30px;
    font-size: 12pt;
    font-weight: bold;
    letter-spacing: 2px;
    text-transform: uppercase;
    text-decoration: none;
    vertical-align: middle;
    color: white;

    &.is-active {
      color: #E41010;
    }

    &:hover {
      color: #770D0D;
    }

    @media only screen and (max-width: 600px) {
      & {
        margin-right: 10px;
        font-size: 10pt;
      }
    }
  }

  .nav-bar-professional-network-link + .nav-bar-professional-network-link {
    margin-left: 10px;
  }

  .nav-bar-professional-network-link {
    &.linkedIn-link:hover path {
      fill: #0077B5;
    }

    &.hacker-rank-link:hover path {
      fill: #27b959;
    }
  }
}
