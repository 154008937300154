.blog-container {
  padding: 24px 10%;

  .blog-entry-image {
    max-width: 100%;
    max-height: 700px;
    display: block;
    margin: 0px auto;
  }

  .container {
    & > ul {
      margin: 0px;

      a {
        color: white;
      }
    }
  }
}

@media only screen and (max-width: 900px) {
  .blog-container {
    padding: 24px 2%;
  }
}

@import './blog-entry-header/index.scss';
@import './blog-entry-paragraph-box/index.scss';
@import './blog-list-entry/index.scss';
