.blog-entry-header {
  display: flex;
  flex-direction: row;

  .title {
    font-size: 36px;
    font-weight: bold;
    margin: 5px auto;
  }

  .description {
    font-size: 16px;
    margin-right: 5px;
    color: #D8D8D8;
  }

  .date {
    display: block;
    font-size: 14px;
    margin: 5px auto;
    color: #848484;
  }
}
