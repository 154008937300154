.rss-list-item {
  display: flex;
  flex-direction: row;

  position: relative;
  border: 2px solid grey;
  border-radius: 10px;
  padding: 4px;
  margin-bottom: 20px;
  background-color: rgba(0,0,0,0.65);

  // Default color.
  color: white;

  &.is-focused {
    outline: 8px outset white;
  }

  .download-button {
    svg {
      padding: 2px;
    }
  }

  & > .body-container {
    flex-grow: 1;
  }

  & > .right-column {
    flex-grow: 0;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    & > * {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }

    & > .save-and-download-buttons {
      display: flex;
      flex-direction: row;
      gap: 6px;
    }
  }

  .rss-list-item-body {
    display: flex;
    flex-direction: row;
    min-height: 70px;
  }

  .image {
    max-width: 100px;
    max-height: 100px;

    // Preserves aspect ratio.
    object-fit: scale-down;

    padding-right: 8px;
    border-radius: 8px 0px 0px 8px;
  }

  .title {
    font-size: 22pt;
  }

  .description {
    font-size: 12pt;
  }

  @media only screen and (max-width: 800px)
  {
    .title {
      font-size: 16pt;
    }

    .description {
      font-size: 10pt;
    }
  }

  .date {
    font-size: 10pt;
  }

  a {
    color: white;
    text-decoration: none;
  }
}
