
.close-button-wrapper {
  position: relative;
  width: 20px;
  height: 20px;

  font-size: 14pt;

  .close-button {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    
    color: white;
  }
}