.work-container {
  padding: 24px 10%;

  .container iframe {
    max-width: 100%;
  }
}

@media only screen and (max-width: 900px) {
  .work-container {
    padding: 24px 2%;
  }
}

@import './2xl-games/index.scss';
@import './gps-insight/index.scss';
@import './rss/index.scss';
