.container {
  min-height: 140px;
  padding: 10px;
  margin-bottom: 40px;
  border-radius: 20px;
  color: white;
  background-color: black;

  &.center-text {
    text-align: center;
  }
}
