.homepage-tile {
  display: inline-block;
  padding: 8px 8px 0px 8px;
  background-color: #1E1E1E;
  border-radius: 10px;
  text-align: center;

  width: 15%;

  @media only screen and (max-width: 900px) {
    & {
      width: 25%;
    }
  }
  @media only screen and (max-width: 550px) {
    & {
      width: 40%;
    }
  }

  & > .link {
    text-decoration: none;

    & > .text {
      color: white;
      height: 40px;
      font-size: 14px;
      letter-spacing: 2px;
      text-decoration: none;
    }
  }

  .image-container {
    & > .homepage-tile-image {
      border-radius: 10px;
      width: 100%;
      aspect-ratio: 1 / 1;
    }

    .image-standard {
      display: block;
    }

    .image-hover {
      display: none;
    }

    &:hover {
      .image-standard {
        display: none;
      }

      .image-hover {
        display: block;
      }
    }
  }
}
